<template>
  <div>
    <!-- Configuration / Insertion ou update d'une entrée -->
    <v-card class="pt-7 pb-3 mb-3">
      <v-card-title>
        <h2 class="text-xl mb-6">Ajout de set-up</h2>
      </v-card-title>
      <v-row>
        <RedacFilter class="ml-6 mr-6" store="redac"></RedacFilter>
      </v-row>
      <!-- <v-row>
        <v-autocomplete
          class="ml-6 mr-6"
          v-model="siteSelected"
          :items="sites"
          item-text="name"
          item-value="id"
          multiple
          outlined
          label="Site"
        ></v-autocomplete>
      </v-row> -->
      <v-row>
        <v-select
          class="ml-6 mr-6"
          v-model="periodSelected"
          :items="periods"
          item-text="name"
          item-value="id"
          multiple
          label="Période"
          outlined
        ></v-select>
      </v-row>
      <v-row>
        <v-text-field
          class="ml-6 mr-6"
          v-model="price"
          label="Prix"
          outlined
        ></v-text-field>
      </v-row>
      <v-row>
        <v-btn color="primary" class="ml-6 mr-6 mb-6" @click="emitSave"
          >Enregistrer</v-btn
        >
        <!-- <v-btn outlined color="secondary" class="ml-6 mr-6 mb-6"
          >Annuler
        </v-btn> -->
      </v-row>
    </v-card>
  </div>
</template>
<script>
import RedacFilter from "@/components/common/filters/RedacFilter.vue";
export default {
  props: ["sites", "periods"],
  components: {
    RedacFilter,
  },
  data() {
    return {
      periodSelected: null,
      price: 0,
    };
  },
  methods: {
    emitSave() {
      // Emit an event with necessary data
      this.$emit("save", {
        redacSelected: this.redacSelected,
        periodSelected: this.periodSelected,
        price: this.price,
      });
    },
  },
  computed: {
    redacSelected() {
      return this.$store.getters["redac/getRedacs"];
    },
  },
};
</script>
